import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { formReducer } from './reducers/formReducer';

const initialState = {

    form: {
        data: {
            nombre: '',
            apellido: '',
            dpi: '',
            telefono: '',
            correo: '',
            moneda1: '',
            monto: '',
            destino: '',
            relacion: true,
            comerciante: false,
            empresa: '',
            telEmpresa: '',
            puesto: '',
            estabilidad: '',
            moneda2: '',
            ingresosRelacion: '',
            moneda3: '',
            ingresosComerciante: '',
            empresaComerciante: '',
            giro: '',
            direccion: '',
            zona: '',
            departamento: '',
            municipio: '',
            finca: '',
            folio: '',
            libro: '',
            archivo: ''
        },
        error: {
            nombre: false,
            apellido: false,
            dpi: false,
            telefono: false,
            monto: false,
            destino: false,
            empresa: false,
            telEmpresa: false,
            puesto: false,
            ingresosRelacion: false,
            ingresosComerciante: false,
            giro: false,
            direccion: false,
            zona: false,
            departamento: false,
            municipio: false,
        },
        required: {
            page1: false,
            page2: false,
            page3: false

        }
    }

};
const reducer = combineReducers({
    form: formReducer
});
const composeEnhacer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, initialState, composeEnhacer(applyMiddleware(thunk)));

export default store;