import React from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import FormScreen from './screens/FormScreen';
import './App.css';

import MainLayout from './layouts/MainLayout';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1D575F'
    }
  }
});

function App() {




  return (
    <MuiThemeProvider theme={theme}>
      <BrowserRouter>
        <MainLayout>
          <Switch>

            <Route path='/form' component={FormScreen} />

            <Route path='/' exact>
              <Redirect to="/form" />
            </Route>
          </Switch>
        </MainLayout>
      </BrowserRouter>
    </MuiThemeProvider>
  );


}

export default App;
