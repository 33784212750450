import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';


import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DoneIcon from '@material-ui/icons/Done';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import logo from '../assets/alapar.png';
import FormScreen1 from './Form1Screen';
import FormScreen2 from './Form2Screen';
import FormScreen3 from './Form3Screen';

import Alert from '@material-ui/lab/Alert';

import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { withStyles } from '@material-ui/core/styles';

import PersonIcon from '@material-ui/icons/Person';
import WorkIcon from '@material-ui/icons/Work';
import HomeIcon from '@material-ui/icons/Home';

import FormHelperText from '@material-ui/core/FormHelperText';

import { useDispatch, useSelector } from 'react-redux';
import { errorPage, changeScreen, start, createLender, save } from '../actions/formActions';
import { TextField } from '@material-ui/core';


function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};





const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});




const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        margin: '0',
    },
    paper: {
        "@media (min-width: 376px)": {
            maxWidth: 600,
            margin: `${theme.spacing(1)}px auto`,
            padding: theme.spacing(2),
            [theme.breakpoints.down("xs")]: {
                maxWidth: "",
            },
        },
        "@media (max-width: 376px)": {
            padding: '0px',
        }
    },
    box: {
        display: "flex",
        width: '100%',
        marginLeft: '0'
    },

    spreadBox: {
        justifyContent: "space-between",
        alignItems: "center"
    },
    box2: {
        height: 20,
        display: "flex",
        margin: 8,
        marginTop: 0
    },
    spreadBox2: {
        justifyContent: "center",
        alignItems: "center"
    },
    typography: {
        align: "center"
    },
    buttonCustom: {
        fontSize: '0.75rem'
    },
    title: {
        width: "100%",
        textAlign: 'center',
        fontWeight: '500 !important',
    },
    formu: {
        flexGrow: 1,
        overflow: 'hidden',
        "@media (min-width: 376px)": {
            padding: theme.spacing(0, 3),
        },
    },
    logo: {
        maxWidth: 50,
        //marginLeft: '100px'
        float: 'left',
    },
    logoMobile: {
        maxWidth: 35,
        //marginLeft: '100px'
        float: 'left',
    }
}));

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);



function FormScreen() {

    const error = useSelector((state) => state.form.error);
    const data = useSelector((state) => state.form.data);

    const [beforeData, setBeforeData] = React.useState({});

    const [open, setOpen] = React.useState(false);
    const [openRestore, setOpenRestore] = React.useState(false);
    const [errorFlag, setErrorFlag] = React.useState(false);
    const [errorText, setErrorText] = React.useState('');

    const [restoreText, setRestoreText] = React.useState('');
    const [restoreError, setRestoreError] = React.useState(false);


    const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions());

    const handleClose = () => {
        setOpen(false);
        window.location.reload(false);
    };

    const handleCloseRestore = () => {
        localStorage.removeItem('token');
        dispatch(start(localStorage.getItem('token'), ''));
        setOpenRestore(false);
    };

    const handleRestoreData = () => {
        const response = dispatch(start(localStorage.getItem('token'), restoreText));
        if (restoreText == '') {
            setRestoreError(true);
            return;
        }
        response.then((data) => {
            if (data.status === 'success') {
                setOpenRestore(false);
            } else {
                setRestoreError(true);
            }
        })
    };

    //const { innerWidth } = window; // Obtiene el valor del ancho de la pantalla

    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

    useEffect(() => {

        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        if (localStorage.getItem('token') === null) {
            dispatch(start(localStorage.getItem('token'), ''));
        } else {
            const response = dispatch(start(localStorage.getItem('token'), ''));
            response.then((res) => {
                console.log(res);
                try {
                    if (res.status === 'success') {
                        const data = JSON.parse(res.data);
                        if (data.apellido !== '') {
                            setOpenRestore(true);
                        } else {
                            localStorage.removeItem('token');
                        }
                    }
                } catch (e) { }
            })
        }

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const dispatch = useDispatch();
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {

        // Validaciones del Form 1
        if (
            (data.nombre === '' ||
                data.telefono === '' || data.telefono === '____-____' ||
                data.correo === '' /* ||
                data.monto === '' ||
                data.destino === '' */) && activeStep === 0
        ) {
            if (data.nombre === '') {
                setErrorFlag(true);
                setErrorText('Se tiene que ingresar un nombre.');
            }

            if ((data.telefono === '' || data.telefono === '____-____') && data.correo === '') {
                setErrorFlag(true);
                setErrorText('Se tiene que ingresar al menos un número de teléfono o el correo electrónico.');
            }

            if ((data.telefono === '' || data.telefono === '____-____') && data.correo === '' && data.nombre === '') {
                setErrorFlag(true);
                setErrorText('Se tiene que ingresar al menos el nombre y un número de teléfono o el correo electrónico.');
            }

            if (!(data.telefono === '' || data.telefono === '____-____') || data.correo !== '') {
                if (data.nombre !== '') {
                } else {
                    dispatch(errorPage({
                        ...error,
                        nombre: data.nombre === '',
                        telefono: data.telefono === '' || data.telefono === '____-____',
                        correo: data.correo === '',
                        /* monto: data.monto === '',
                        destino: data.destino === '' */
                    }));
                    return;
                }
            } else {
                dispatch(errorPage({
                    ...error,
                    nombre: data.nombre === '',
                    telefono: data.telefono === '' || data.telefono === '____-____',
                    correo: data.correo === '',
                    /* monto: data.monto === '',
                    destino: data.destino === '' */
                }));
                return;
            }


            //setActiveStep((prevActiveStep) => 0);
        }

        if (data.dpi !== '____ _____ ____') {
            for (var i = 0; i < data.dpi.length; i++) {
                if (data.dpi[i] === '_') {
                    setErrorFlag(true);
                    setErrorText('Llenar correctamente el campo de DPI');
                    dispatch(errorPage({
                        ...error,
                        dpi: true,
                    }));
                    return;
                }
            }
        }

        var errorTelefono = false;
        var errorCorreo = false;


        if (data.correo !== '') {
            var regex = /^[a-zA-Z0-9][a-zA-Z0-9].*@[a-zA-Z0-9][a-zA-Z0-9].*\.[a-zA-Z0-9][a-zA-Z0-9].*/g;
            if (!regex.test(data.correo)) {
                errorCorreo = true;
            }
        }

        for (i = 0; i < data.telefono.length; i++) {
            if (data.telefono[i] === '_') {
                errorTelefono = true;
            }
        }


        if (errorCorreo && errorTelefono) {
            setErrorFlag(true);
            setErrorText('Ingrese un medio de comunicación válido.');
            dispatch(errorPage({
                ...error,
                correo: true,
                telefono: true,
            }));
            return;
        }

        var flagError = false;

        if (errorTelefono) {
            if (data.correo === '') {
                setErrorFlag(true);
                setErrorText('Llenar correctamente el campo de teléfono');
                dispatch(errorPage({
                    ...error,
                    telefono: true,
                }));
                return;
            } else {
                if (errorCorreo) {
                    setErrorFlag(true);
                    setErrorText('Ingresar un correo electrónico válido.');
                    dispatch(errorPage({
                        ...error,
                        correo: true,
                    }));
                    return;
                }
            }
        } else {
            flagError = true;
        }

        if (errorCorreo && !flagError) {
            if (data.correo !== '') {
                setErrorFlag(true);
                setErrorText('Ingresar un correo electrónico válido.');
                dispatch(errorPage({
                    ...error,
                    correo: true,
                }));
                return;
            }
        } else if ((data.telefono === '' || data.telefono === '____-____') && (errorCorreo)) {
            if (data.correo !== '') {
                setErrorFlag(true);
                setErrorText('Ingresar un correo electrónico válido.');
                dispatch(errorPage({
                    ...error,
                    correo: true,
                }));
                return;
            }
        }


        if (activeStep === 0) {
            dispatch(changeScreen({
                ...error,
                nombre: false,
                apellido: false,
                dpi: false,
                telefono: false,
                monto: false,
                destino: false,
                correo: false
            }, data, true));
            setErrorFlag(false);
        }
        // Validaciones del Form 2
        /* if ((data.empresa === '' ||
            data.telEmpresa === '' || data.telefono === '____-____' ||
            data.puesto === '' ||
            data.ingresosRelacion === '' ||
            data.ingresosComerciante === '' ||
            data.giro === '') && activeStep === 1) {
            //setActiveStep((prevActiveStep) => 1);

            if (data.relacion && data.comerciante) {
                dispatch(errorPage({
                    ...error,
                    empresa: data.empresa === '',
                    telEmpresa: data.telEmpresa === '' || data.telefono === '____-____',
                    puesto: data.puesto === '',
                    ingresosRelacion: data.ingresosRelacion === '',
                    ingresosComerciante: data.ingresosComerciante === '',
                    giro: data.giro === '',
                }));
                return;
            } else if (data.relacion &&
                (data.empresa === '' ||
                    data.telEmpresa === '' ||
                    data.puesto === '' ||
                    data.ingresosRelacion === '')) {
                dispatch(errorPage({
                    ...error,
                    empresa: data.empresa === '',
                    telEmpresa: data.telEmpresa === '' || data.telefono === '____-____',
                    puesto: data.puesto === '',
                    ingresosRelacion: data.ingresosRelacion === '',
                }));
                return;
            } else if (data.comerciante &&
                (data.ingresosComerciante === '' ||
                    data.giro === '')) {
                dispatch(errorPage({
                    ...error,
                    ingresosComerciante: data.ingresosComerciante === '',
                    giro: data.giro === '',
                }));
                return;
            }
        } */

        /* for (i = 0; i < data.telEmpresa.length; i++) {
            if (data.telEmpresa[i] === '_') {
                setErrorFlag(true);
                setErrorText('Llenar correctamente el campo de teléfono');
                dispatch(errorPage({
                    ...error,
                    telEmpresa: true,
                }));
                return;
            }
        } */
        if (activeStep === 1) {
            dispatch(changeScreen({
                ...error,
                empresa: false,
                telEmpresa: false,
                puesto: false,
                ingresosRelacion: false,
                ingresosComerciante: false,
                giro: false,
            }, data));
            setErrorFlag(false);
        }

        // Validaciones del Form 3
        /* if ((data.direccion === '' ||
            data.zona === '' ||
            data.departamento === '' ||
            data.municipio === '') && activeStep === 2) {
            dispatch(errorPage({
                ...error,
                direccion: data.direccion === '',
                zona: data.zona === '',
                departamento: data.departamento === '',
                municipio: data.municipio === '',
            }));
            return;
        } */

        if (activeStep === 2) {
            dispatch(changeScreen({
                ...error,
                direccion: false,
                zona: false,
                departamento: false,
                municipio: false,
            }, data));
            setErrorFlag(false);
        }

        if (activeStep === 2) {
            dispatch(createLender(data));
            localStorage.removeItem('token');
            setOpen(true);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        dispatch(changeScreen(error, data));
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };



    function getSteps() {
        return ['Datos personales', 'Fuentes de ingresos', 'Propiedad a hipotecar'];
    }







    return (
        <>


            <ElevationScroll>
                <AppBar className={classes.appbar} >

                    <Toolbar>
                        <a href='https://alapar.com.gt' style={{ position: 'absolute' }}>
                            <img src={logo} alt='logo' className={windowDimensions.width < 376 ? classes.logoMobile : classes.logo} />
                        </a>
                        <Typography variant={(windowDimensions.width < 376 && windowDimensions.width > 320) ? "subtitle1" : (windowDimensions.width <= 320 ? 'subtitle2' : "h6")} className={classes.title}>
                            Formulario de Pre-Aprobación
                        </Typography>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            <br /><br />
            <div className={classes.root}>
                <Paper className={classes.paper} elevation={windowDimensions.width < 376 ? 0 : 1}>
                    <div className={classes.title}>
                        <Box
                            component="span"
                            m={1}
                            className={`${classes.spreadBox2} ${classes.box2}`}
                        >
                            {activeStep === 0 ? <><PersonIcon /> <Typography variant="h6" className={classes.typography}> Datos Personales</Typography></> : null}
                            {activeStep === 1 ? <><WorkIcon /><Typography variant="h6" className={classes.typography}> Fuentes de Ingresos</Typography></> : null}
                            {activeStep === 2 ? <><HomeIcon /> <Typography variant="h6" className={classes.typography}>Propiedad a Hipotecar</Typography></> : null}

                        </Box>

                    </div>

                    <hr style={{ marginLeft: windowDimensions.width < 376 ? -5 : 16, marginRight: windowDimensions.width < 376 ? -5 : 16 }} />
                    {activeStep === 0 ? <FormScreen1 /> : null}
                    {activeStep === 1 ? <FormScreen2 /> : null}
                    {activeStep === 2 ? <FormScreen3 /> : null}
                    {/* <hr style={{ marginLeft: windowDimensions.width < 376 ? -5 : 16, marginRight: windowDimensions.width < 376 ? -5 : 16 }} /> */}
                    {errorFlag ? <><br /><Alert severity="error">{errorText}</Alert><br /></> : <></>}

                    <Grid container wrap="nowrap" spacing={2}>
                        <form className={classes.formu} autoComplete="off">
                            <Box
                                component="span"
                                m={1}
                                className={`${classes.spreadBox} ${classes.box}`}
                            >
                                <Button
                                    disabled={activeStep === 0} onClick={handleBack}
                                    variant="contained"
                                    color="primary"
                                    startIcon={<ArrowBackIosIcon />}
                                    size="small"
                                    className={classes.buttonCustom}
                                >
                                    Anterior
                                </Button>
                                {activeStep === 0 ? <FormHelperText>1 de 3</FormHelperText> : null}
                                {activeStep === 1 ? <FormHelperText>2 de 3</FormHelperText> : null}
                                {activeStep === 2 ? <FormHelperText>3 de 3</FormHelperText> : null}


                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"

                                    onClick={handleNext}
                                    className={classes.buttonCustom}

                                    endIcon={activeStep === steps.length - 1 ? <DoneIcon /> : <ArrowForwardIosIcon />}
                                >
                                    {activeStep === steps.length - 1 ? 'Enviar' : 'Siguiente'}
                                </Button>
                            </Box>



                        </form>
                    </Grid>
                    <Dialog aria-labelledby="customized-dialog-title" open={open}>
                        <DialogTitle id="customized-dialog-title" >
                            Gracias por tu información
                        </DialogTitle>
                        <DialogContent dividers>
                            <Typography gutterBottom>
                                Tu asesor asignado dará una
                                revisión a tus datos y te
                                mantendremos informado de tu
                                proceso de crédito.
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={handleClose} color="primary">
                                Cerrar
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Paper>

            </div>
            <Dialog aria-labelledby="customized-dialog-title" open={openRestore}>
                <DialogTitle id="customized-dialog-title" >
                    Recuperación de datos
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Se ha detectado que ya estaba llenando este formulario, ¿desea continuar desde donde se quedó? <br />
                        <Typography variant='caption'>
                            Para hacerlo tiene que ingresar el apellido que se ha ingresado anteriormente.
                        </Typography>
                    </Typography>
                    <TextField
                        id="outlined-full-width"
                        label="Apellidos"
                        style={{ marginRight: 8, marginTop: 8, marginBottom: 8 }}
                        fullWidth
                        helperText={!restoreError ? '' : 'El apellido ingresado no coincide con el ingresado anteriormente.'}
                        margin="normal"
                        variant="outlined"
                        size="small"
                        inputProps={{ style: { fontSize: '0.8rem' } }}
                        InputLabelProps={{ style: { fontSize: '0.8rem' } }}
                        error={restoreError}
                        name="dpi"
                        onChange={(e) => setRestoreText(e.target.value)}
                        value={restoreText}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseRestore} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleRestoreData} color="primary">
                        Restaurar
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );

}





export default FormScreen;