import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Grid from '@material-ui/core/Grid';


import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import DividerWithText from './DividerWithText';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';



import { second, errorPage, save } from '../actions/formActions';

import { useDispatch, useSelector } from 'react-redux';


import useScrollTrigger from '@material-ui/core/useScrollTrigger';



function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};


function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[1-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}

            showMask
        />
    );
}

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

function TextMaskCustom2(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[1-9]/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}

            showMask
        />
    );
}

TextMaskCustom2.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
        //prefix="$"
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const currencies = [
    {
        value: 'GTQ',
        label: 'Q',
    },
    {
        value: 'USD',
        label: '$',
    }
];

const years = [
    {
        value: '1',
        label: 'De 0 a 1 años',
    },
    {
        value: '2',
        label: 'De 1 a 5 años',
    },
    {
        value: '3',
        label: 'De 5 a 10 años',
    },
    {
        value: '4',
        label: 'De 10 en adelante ',
    }
];



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        padding: theme.spacing(0, 3),
    },
    paper: {
        maxWidth: 600,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },
    dividerFullWidth: {
        margin: `5px 0 0 ${theme.spacing(2)}px`,
    },
    spreadBox: {
        justifyContent: "space-between",
        alignItems: "center"
    },
    box: {
        display: "flex",
        width: '100%',
        margin: 8,
        marginLeft: 0,
    },
    item: {
        flexGrow: 4,
    },
    buttonCustom: {
        fontSize: '0.74rem'
    },
    check: {
        marginLeft: -1,
    }
}));


function FormScreen2() {

    const data = useSelector((state) => state.form.data);

    const dispatch = useDispatch();

    const error = useSelector((state) => state.form.error);

    const { relacion, comerciante, empresa, telEmpresa, puesto, estabilidad, moneda2, ingresosRelacion, moneda3, ingresosComerciante, empresaComerciante, giro } = data;

    const onChangeFormulario = e => {
        /* if (error[e.target.name]) {
            if((e.target.name === 'telEmpresa') && !isNaN(parseInt(e.target.value))){
                dispatch(errorPage({
                    ...error,
                    [e.target.name]: false,
                }));
            } else if (!(e.target.name === 'telEmpresa')) {
                dispatch(errorPage({
                    ...error,
                    [e.target.name]: false,
                }));
            }
        } */

        const beforeData = data;

        dispatch(second({
            ...data,
            [e.target.name]: e.target.value
        }, beforeData));
    }



    const handleChange = (event) => {

        dispatch(second({
            ...data,
            [event.target.name]: event.target.checked
        }));

        if (comerciante === false && event.target.name === 'relacion' && event.target.checked === false) {
            dispatch(second({
                ...data,
                comerciante: true,
                relacion: false
            }));
        }
        if (relacion === false && event.target.name === 'comerciante' && event.target.checked === false) {
            dispatch(second({
                ...data,
                relacion: true,
                comerciante: false
            }));
        }
    };

    const [expanded, setExpanded] = React.useState('panel1');

    const handleChangee = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
        if (!isExpanded) {
            setExpanded2('panel2');
        }
    };

    const [expanded2, setExpanded2] = React.useState(false);

    const handleChangee2 = (panel) => (event, isExpanded) => {
        setExpanded2(isExpanded ? panel : false);
        if (!isExpanded) {
            setExpanded('panel1');
        }

    };


    /* useEffect(() => {
        const timeOut = setInterval(() => {
            console.log(data);
            dispatch(save(data, { save: true }));
        }, 3000)

        return () => {
            clearInterval(timeOut);
        }
    }, []) */



    const classes = useStyles();


    return (
        <>

            <Grid container wrap="nowrap" spacing={2}>
                <form className={classes.root} autoComplete="off">
                    <br />
                    <div>
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChangee('panel1')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Relación de dependencia</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <TextField
                                        id="outlined-full-width"
                                        label="Nombre de la empresa"
                                        style={{ marginRight: 8, marginTop: 8, marginBottom: 8 }}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        //required
                                        inputProps={{ style: { fontSize: '0.8rem' } }}
                                        InputLabelProps={{ style: { fontSize: '0.8rem' } }}
                                        error={error.empresa}
                                        name="empresa"
                                        value={empresa}
                                        onChange={onChangeFormulario}

                                    />
                                    <TextField
                                        id="outlined-full-width"
                                        label="Télefono de la empresa"
                                        style={{ marginRight: 8, marginTop: 8, marginBottom: 8 }}
                                        InputProps={{
                                            inputComponent: TextMaskCustom2,
                                        }}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        //required
                                        inputProps={{ style: { fontSize: '0.8rem' } }}
                                        InputLabelProps={{ style: { fontSize: '0.8rem' } }}
                                        error={error.telEmpresa}
                                        name="telEmpresa"
                                        value={telEmpresa}
                                        onChange={onChangeFormulario}

                                    />
                                    <TextField
                                        id="outlined-full-width"
                                        label="Puesto que desempeña actualmente"
                                        style={{ marginRight: 8, marginTop: 8, marginBottom: 8 }}

                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        //required
                                        inputProps={{ style: { fontSize: '0.8rem' } }}
                                        InputLabelProps={{ style: { fontSize: '0.8rem' } }}
                                        error={error.puesto}
                                        name="puesto"
                                        value={puesto}
                                        onChange={onChangeFormulario}


                                    />


                                    <TextField
                                        id="outlined-select-currency-native"
                                        select
                                        fullWidth
                                        label="Estabilidad Laboral"
                                        //required
                                        style={{ marginRight: 8, marginTop: 8, marginBottom: 8 }}
                                        SelectProps={{
                                            native: true,
                                        }}

                                        variant="outlined"
                                        size="small"
                                        inputProps={{ style: { fontSize: '0.8rem' } }}
                                        InputLabelProps={{ style: { fontSize: '0.8rem' } }}
                                        name="estabilidad"
                                        value={estabilidad}
                                        onChange={onChangeFormulario}

                                    >
                                        {years.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </TextField>
                                    <Box
                                        component="span"
                                        m={1}
                                        className={`${classes.spreadBox} ${classes.box}`}
                                    >

                                        <TextField
                                            id="outlined-select-currency-native"
                                            select
                                            SelectProps={{
                                                native: true,
                                            }}
                                            variant="outlined"
                                            size="small"
                                            inputProps={{ style: { fontSize: '0.8rem' } }}
                                            InputLabelProps={{ style: { fontSize: '0.8rem' } }}
                                            name="moneda2"
                                            value={moneda2}
                                            onChange={onChangeFormulario}
                                        >
                                            {currencies.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </TextField>
                                        <TextField
                                            id="outlined-full-width"
                                            label="Ingresos"
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            variant="outlined"
                                            size="small"
                                            //required
                                            style={{ marginLeft: 4 }}
                                            className={classes.item}
                                            inputProps={{ style: { fontSize: '0.8rem' } }}
                                            InputLabelProps={{ style: { fontSize: '0.8rem' } }}
                                            error={error.ingresosRelacion}
                                            name="ingresosRelacion"
                                            value={ingresosRelacion}
                                            onChange={onChangeFormulario}

                                        />
                                    </Box>
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded2 === 'panel2'} onChange={handleChangee2('panel2')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header"
                            >
                                <Typography>Comerciante/Profesional</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                    <Box
                                        component="span"
                                        m={1}
                                        className={`${classes.spreadBox} ${classes.box}`}
                                    >

                                        <TextField
                                            id="outlined-select-currency-native"
                                            select
                                            SelectProps={{
                                                native: true,
                                            }}
                                            variant="outlined"
                                            size="small"
                                            inputProps={{ style: { fontSize: '0.8rem' } }}
                                            InputLabelProps={{ style: { fontSize: '0.8rem' } }}
                                            name="moneda3"
                                            value={moneda3}
                                            onChange={onChangeFormulario}
                                        >
                                            {currencies.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </TextField>
                                        <TextField
                                            id="outlined-full-width"
                                            label="Ingresos"
                                            InputProps={{
                                                inputComponent: NumberFormatCustom,
                                            }}
                                            variant="outlined"
                                            size="small"
                                            //required
                                            style={{ marginLeft: 4 }}
                                            className={classes.item}
                                            inputProps={{ style: { fontSize: '0.8rem' } }}
                                            InputLabelProps={{ style: { fontSize: '0.8rem' } }}
                                            error={error.ingresosComerciante}
                                            name="ingresosComerciante"
                                            value={ingresosComerciante}
                                            onChange={onChangeFormulario}

                                        />
                                    </Box>
                                    <TextField
                                        id="outlined-full-width"
                                        label="Nombre de la empresa (si posee)"
                                        style={{ marginRight: 8, marginTop: 8, marginBottom: 8 }}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        inputProps={{ style: { fontSize: '0.8rem' } }}
                                        InputLabelProps={{ style: { fontSize: '0.8rem' } }}
                                        name="empresaComerciante"
                                        value={empresaComerciante}
                                        onChange={onChangeFormulario}

                                    />

                                    <TextField
                                        id="outlined-full-width"
                                        label="Giro de Negocio / Profesión u Oficio"
                                        style={{ marginRight: 8, marginTop: 8, marginBottom: 8 }}
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        //required
                                        inputProps={{ style: { fontSize: '0.8rem' } }}
                                        InputLabelProps={{ style: { fontSize: '0.8rem' } }}
                                        error={error.giro}
                                        name="giro"
                                        value={giro}
                                        onChange={onChangeFormulario}

                                    />
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                    <br />


                </form>


            </Grid>
        </>
    );

}





export default FormScreen2;