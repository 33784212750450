import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import Grid from '@material-ui/core/Grid';


import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';


import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import FormHelperText from '@material-ui/core/FormHelperText';

import { useDispatch, useSelector } from 'react-redux';
import { first, saveMail, errorPage } from '../actions/formActions';



function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};


function TextMaskCustom(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[1-9]/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/]}

            showMask
        />
    );
}

TextMaskCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

function TextMaskCustom2(props) {
    const { inputRef, ...other } = props;


    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/[1-9]/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}

            showMask
        />
    );
}

TextMaskCustom2.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator
            isNumericString
        //prefix="$"
        />
    );
}

NumberFormatCustom.propTypes = {
    inputRef: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const currencies = [
    {
        value: 'GTQ',
        label: 'Q',
    },
    {
        value: 'USD',
        label: '$',
    }
];



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        overflow: 'hidden',
        "@media (min-width: 376px)": {
            padding: theme.spacing(0, 3),
        },
    },
    paper: {
        maxWidth: 600,
        margin: `${theme.spacing(1)}px auto`,
        padding: theme.spacing(2),
    },
    spreadBox: {
        justifyContent: "space-between",
        alignItems: "center"
    },
    box: {
        display: "flex",
        width: '100%',
        marginLeft: 0,
    },
    item: {
        flexGrow: 4,
    },
    typography: {
        flexGrow: 1,
        align: "center"
    }
}));


function FormScreen1() {
    const error = useSelector((state) => state.form.error);
    const data = useSelector((state) => state.form.data);

    const dispatch = useDispatch();

    const classes = useStyles();

    const { nombre, apellido, dpi, telefono, correo, moneda1, monto, destino } = data;
    const [firstSend, setFirstSend] = React.useState(false);

    const onChangeFormulario = e => {
        if (error[e.target.name]) {
            if ((/* e.target.name === 'dpi' || */ e.target.name === 'telefono') && !isNaN(parseInt(e.target.value))) {
                dispatch(errorPage({
                    ...error,
                    [e.target.name]: false,
                }));
            } else if (!(/* e.target.name === 'dpi' || */ e.target.name === 'telefono' || e.target.name === 'correo')) {
                dispatch(errorPage({
                    ...error,
                    [e.target.name]: false,
                }));
            }

        }

        const beforeData = data;

        const response = dispatch(first(beforeData, {
            ...data,
            [e.target.name]: e.target.value
        }, (e.target.name == 'correo' ? true : firstSend)));

        response.then((res) => {
            try {
                if (res.status === 'success' || res.message === 'success') {
                    if(e.target.name !== 'correo'){
                        setFirstSend(true);
                    }
                }
            } catch (e) { }
        });
    }

    const validateMail = () => {
        var regex = /^[a-zA-Z0-9][a-zA-Z0-9].*@[a-zA-Z0-9][a-zA-Z0-9].*\.[a-zA-Z0-9][a-zA-Z0-9].*/g;
        if (regex.test(data.correo)) {
            if (error['correo']) {
                dispatch(errorPage({
                    ...error,
                    correo: false,
                }));
            }
            const beforeData = data;
            const response = dispatch(saveMail(beforeData, { ...data, }, firstSend));
            setFirstSend(false);
            response.then((res) => {
                try {
                    if (res.status === 'success' || res.message === 'success') {
                        setFirstSend(true);
                    }
                } catch (e) { }
            });
        } else {
            if (data.telefono === '' || data.telefono === '____-____') {
                dispatch(errorPage({
                    ...error,
                    correo: true,
                }));
            } else {
                var testPhone = false;
                for (var i = 0; i < data.telefono.length; i++) {
                    if (data.telefono[i] === '_') {
                        testPhone = true;
                    }
                }

                if (testPhone) {
                    dispatch(errorPage({
                        ...error,
                        correo: true,
                    }));
                } else {
                    dispatch(errorPage({
                        ...error,
                        correo: false,
                    }));
                }
            }

            /* dispatch(errorPage({
                ...error,
                correo: true,
            })); */
        }
        /* const beforeData = data;
        const response = dispatch(saveMail(beforeData, { ...data }, firstSend));
        console.log(response);

        response.then((res) => {
            try {
                if (res.status === 'success' || res.message === 'success') {
                    setFirstSend(true);
                }
            } catch (e) { }
        }); */
    }

    return (
        <>
            <Grid container wrap="nowrap" spacing={2}>
                <form className={classes.root} autoComplete="off">
                    <TextField
                        id="outlined-full-width"
                        label="Nombres"
                        style={{ marginRight: 8, marginTop: 8, marginBottom: 8 }}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        size="small"
                        /* required */
                        inputProps={{ style: { fontSize: '0.8rem' } }}
                        InputLabelProps={{ style: { fontSize: '0.8rem' } }}
                        error={error.nombre}
                        name="nombre"
                        value={nombre}
                        onChange={onChangeFormulario}


                    />
                    <TextField
                        id="outlined-full-width"
                        label="Apellidos"
                        style={{ marginRight: 8, marginTop: 8, marginBottom: 8 }}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        size="small"
                        /* required */
                        inputProps={{ style: { fontSize: '0.8rem' } }}
                        InputLabelProps={{ style: { fontSize: '0.8rem' } }}
                        error={error.apellido}
                        name="apellido"
                        value={apellido}
                        onChange={onChangeFormulario}

                    />
                    <TextField
                        id="outlined-full-width"
                        label="DPI"

                        style={{ marginRight: 8, marginTop: 8, marginBottom: 8 }}
                        helperText="Tu DPI no debe tener más de 13 dígitos"
                        InputProps={{
                            inputComponent: TextMaskCustom,
                        }}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        size="small"
                        inputProps={{ style: { fontSize: '0.8rem' } }}
                        InputLabelProps={{ style: { fontSize: '0.8rem' } }}
                        error={error.dpi}
                        name="dpi"
                        value={dpi}
                        onChange={onChangeFormulario}

                    />
                    <TextField
                        id="outlined-full-width"
                        label="Télefono/Móvil"
                        style={{ marginRight: 8, marginTop: 8, marginBottom: 8 }}
                        InputProps={{
                            inputComponent: TextMaskCustom2,
                        }}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        size="small"
                        /* required */
                        inputProps={{ style: { fontSize: '0.8rem' } }}
                        InputLabelProps={{ style: { fontSize: '0.8rem' } }}
                        error={error.telefono}
                        name="telefono"
                        value={telefono}
                        onChange={onChangeFormulario}
                    />
                    <TextField
                        id="outlined-full-width"
                        label="Correo Electrónico"
                        style={{ marginRight: 8, marginTop: 8, marginBottom: 8 }}
                        type="email"
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        size="small"
                        inputProps={{ style: { fontSize: '0.8rem' } }}
                        InputLabelProps={{ style: { fontSize: '0.8rem' } }}
                        name="correo"
                        error={error.correo}
                        value={correo}
                        onChange={onChangeFormulario}
                        onBlur={validateMail}

                    />
                    <Box
                        component="span"
                        m={1}
                        className={`${classes.spreadBox} ${classes.box}`}
                    >

                        <TextField
                            id="outlined-select-currency-native"
                            select
                            SelectProps={{
                                native: true,
                            }}
                            variant="outlined"
                            size="small"
                            inputProps={{ style: { fontSize: '0.8rem' } }}
                            InputLabelProps={{ style: { fontSize: '0.8rem' } }}
                            name="moneda1"
                            value={moneda1}
                            onChange={onChangeFormulario}
                        >
                            {currencies.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </TextField>
                        <TextField
                            id="outlined-full-width"
                            label="Monto Solicitado"
                            InputProps={{
                                inputComponent: NumberFormatCustom,
                            }}
                            variant="outlined"
                            size="small"
                            /* required */
                            style={{ marginLeft: 4 }}
                            className={classes.item}
                            inputProps={{ style: { fontSize: '0.8rem' } }}
                            InputLabelProps={{ style: { fontSize: '0.8rem' } }}
                            error={error.monto}
                            name="monto"
                            value={monto}
                            onChange={onChangeFormulario}

                        />
                    </Box>
                    <TextField
                        id="outlined-multiline-static"
                        label="Propósito de los fondos"
                        style={{ marginRight: 8, marginTop: 8, marginBottom: 8 }}
                        fullWidth
                        multiline
                        rows={4}
                        variant="outlined"
                        size="small"
                        /* required */
                        inputProps={{ style: { fontSize: '0.8rem' } }}
                        InputLabelProps={{ style: { fontSize: '0.8rem' } }}
                        error={error.destino}
                        name="destino"
                        value={destino}
                        onChange={onChangeFormulario}


                    />
                    {/* <FormHelperText style={{ marginRight: 8, marginTop: 4, marginBottom: 4 }}>(*) Obligatorio</FormHelperText> */}
                </form>




            </Grid>
        </>
    );

}





export default FormScreen1;