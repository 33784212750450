
const ApiURLs = {
    //"baseURL": 'https://dev.app.leads.alapar.local/v1',
    "baseURL": 'https://leads-api.qa.alapar.com.gt/v1',
    //"baseURL": 'https://leads-api.alapar.com.gt/v1',
    "CreateToken": {
        'href': '/auth/create-token',
        'method': 'post',
        'requires': [
            'username',
            'password'
        ]
    },
    "AuthSession": {
        'href': '/auth/auth',
        'method': 'post',
        'requires': [
            'email',
            'password',
            'remember'
        ]
    },
    "CheckApplication": {
        'href': '/auth/check-application',
        'method': 'post',
    },
    "RestoreData": {
        'href': '/auth/restore-checkout',
        'method': 'post',
    },
    "SaveApplication": {
        'href': '/auth/save-application',
        'method': 'post',
    },
    "SaveLead": {
        'href': '/auth/save-lead',
        'method': 'post',
    },
    "ReadUserBatch": {
        'href': '/glifos/api/checkin/batch',
        'method': 'post',
    },
    "UploadFile": {
        'href': '/glifos/file/upload',
        'method': 'post',
    },
    "dowloadFile": {
        'href': '/glifos/file/download/',
        'method': 'get',
    }
};


export default ApiURLs;