import React from 'react';


import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';



import MainLayoutStyle from '../assets/jss/glifos/mainLayoutStyle';




const useStyles = makeStyles(MainLayoutStyle);

export default function MainLayout(props) {

    const classes = useStyles();



    return <Container maxWidth="lg" className={classes.container}>{props.children}</Container>;

}
