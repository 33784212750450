import { FIRST_PAGE, SECOND_PAGE, THREE_PAGE, ERROR_PAGE, CHANGE_SCREEN, INIT, START, AUTH_ERROR, AUTH_SUCCESS, FINISH_APPLICATION, RESTORE_APPLICATION, SAVE_APPLICATION } from "../constants/formConstants";

const initialState = {
    token: localStorage.getItem('token') !== null ? localStorage.getItem('token') : '',
    data: {
        nombre: '',
        apellido: '',
        dpi: '',
        telefono: '',
        correo: '',
        moneda1: '',
        monto: '',
        destino: '',
        relacion: '',
        comerciante: '',
        empresa: '',
        telEmpresa: '',
        puesto: '',
        estabilidad: '',
        moneda2: '',
        ingresosRelacion: '',
        moneda3: '',
        ingresosComerciante: '',
        empresaComerciante: '',
        giro: '',
        direccion: '',
        zona: '',
        departamento: '',
        municipio: '',
        finca: '',
        folio: '',
        libro: '',
        archivo: ''
    },
    error: {
        nombre: false,
        apellido: false,
        dpi: false,
        telefono: false,
        monto: false,
        destino: false,
        empresa: false,
        telEmpresa: false,
        puesto: false,
        ingresosRelacion: false,
        ingresosComerciante: false,
        giro: false,
        direccion: false,
        zona: false,
        departamento: false,
        municipio: false,
    },
    required: {
        page1: false,
        page2: false,
        page3: false
    }
}


export const formReducer = (state = initialState, action) => {
    switch (action.type) {
        case INIT:
        case START:
        case AUTH_SUCCESS: {
            return { ...state, token: action.payload };
        }
        case AUTH_ERROR: {
            return { ...state, token: action.payload };
        }
        case SAVE_APPLICATION:
        case FIRST_PAGE: {
            return { ...state, data: action.payload };
        }
        case SECOND_PAGE: {
            return { ...state, data: action.payload };
        }
        case FINISH_APPLICATION:
        case THREE_PAGE: {
            return { ...state, data: action.payload };
        }
        case CHANGE_SCREEN:
        case ERROR_PAGE: {
            return { ...state, error: action.payload };
        }
        case RESTORE_APPLICATION: {
            return { ...state, data: action.payload, token: action.token };
        }

        /* case FINISH_APPLICATION: {
            return {};
        } */
        default: return state;
    }
}
