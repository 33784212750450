export const INIT = "INIT";
export const START = "START";
export const FIRST_PAGE = "FIRST_PAGE";
export const SECOND_PAGE = "SECOND_PAGE";
export const THREE_PAGE = "THREE_PAGE";
export const ERROR_PAGE = "ERROR_PAGE";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const CHANGE_SCREEN = "CHANGE_SCREEN";
export const FINISH_APPLICATION = "FINISH_APPLICATION";
export const RESTORE_APPLICATION = "RESTORE_APPLICATION";
export const SAVE_APPLICATION = "SAVE_APPLICATION";
